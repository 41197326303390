<template>
  <app-modal @close="$emit('cancel')">
    <template #header>
      <h5>{{ $t(`${langPath}.title`, { title: position.title }) }}</h5>
    </template>
    <main class="archive-position-modal__body">
      <p class="archive-position-modal__body__paragraph">
        {{ $t(`${langPath}.firstParagraph`) }}
      </p>
      <p class="archive-position-modal__body__paragraph">
        {{ $t(`${langPath}.secondParagraph`) }}
      </p>
    </main>
    <template #footer>
      <footer class="archive-position-modal__footer">
        <ev-button
          variant="tertiary"
          color="blue"
          data-test="cancel-button"
          @click="$emit('cancel')"
        >
          {{ $t(`${langPath}.cancel`) }}
        </ev-button>

        <ev-button
          data-test="confirm-button"
          @click="$emit('confirm')"
        >
          {{ $t(`${langPath}.confirm`) }}
        </ev-button>
      </footer>
    </template>
  </app-modal>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'

export default {
  name: 'ArchivePositionModal',
  components: {
    EvButton
  },
  props: {
    position: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss">
.archive-position-modal {
  &__body {
    padding: $base*6 0;

    &__paragraph {
      @extend %body-text1;

      margin-bottom: $base * 5;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
