<template>
  <section class="positions-skeleton">
    <skeleton class="positions-skeleton__card" />
    <skeleton class="positions-skeleton__card" />
    <skeleton class="positions-skeleton__card" />
    <skeleton class="positions-skeleton__card" />
    <skeleton class="positions-skeleton__card" />
    <skeleton class="positions-skeleton__card" />
  </section>
</template>

<script>
import Skeleton from './Skeleton'

export default {
  name: 'PositionsSkeleton',

  components: {
    Skeleton
  }
}
</script>

<style lang="scss">
.positions-skeleton {
  --card-skeleton-height: 300px;
  --card-skeleton-width: 400px;

  align-items: stretch;
  display: grid;
  gap: $base * 6;
  grid-template-columns: repeat(auto-fit, minmax(394px, 1fr));
  position: relative;

  &__card {
    box-shadow: $shadow-8;
    height: var(--card-skeleton-height);
    margin: 0 $base*2;
    width: var(--card-skeleton-width);
  }

  &::after {
    background: $gray5;
    background: linear-gradient(0deg, $gray5 0%, rgba($gray5, 0) 100%);
    bottom: 0;
    content: '';
    height: 30%;
    position: absolute;
    width: 100%;
  }
}
</style>
