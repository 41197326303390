<template>
  <article
    class="position-card"
    :class="positionCardClass"
  >
    <div class="position-card__container" @click="redirectToWorkflowBoard" >
      <header class="position-card__header">
        <div class="position-card__header-container">
          <div
            v-if="isPrivate"
            class="position-card__header-private-icon"
          >
            <ev-icon
              name="lock-alt"
              data-test="private-icon"
              color="gray80"
            />
          </div>

          <div class="position-card__header-title">
            <h4
              class="position-card__title"
              data-test="title"
            >
              {{ position.title }}
            </h4>
          </div>

          <div
            v-if="isDraft"
            class="position-card__draft-badge"
          >
            <ev-badge
              :title="$t(`${langPath}.inDraft.status`)"
              variant="white"
              data-test="draft-badge"
            />
          </div>

        </div>
      </header>

      <div
        v-if="!isDraft"
        class="position-card__details"
      >
        <ev-badge
          v-for="(contractType, index) in contractTypes"
          :key="index"
          class="position-card__details-badge"
          data-test="contract-type-badge"
          variant="red-light"
          :title="contractType"
        />

        <ev-badge
          v-if="isRemote"
          class="position-card__details-badge"
          data-test="remote-badge"
          variant="red-light"
          :title="$t(`${langPath}.remoteLocation`)"
        />

        <ev-badge
          v-if="hasLocationBadge"
          class="position-card__details-badge"
          data-test="location-badge"
          variant="red-light"
          :title="locationBadgeText"
        />
      </div>

      <div class="position-card__body">
        <div class="position-card__body-container">
          <ul class="position-card__body-items">
            <li class="position-card__body-item position-card__invites-sent">
              <span class="position-card__body-item-count">
                {{ position.nextInterviewsCount }}
              </span>
              <p class="position-card__body-item-description">
                {{ $t(`${langPath}.interviews`) }}
              </p>
            </li>

            <li class="position-card__body-item position-card__on-process">
              <span class="position-card__body-item-count">
                {{ position.activeApplicantsCount }}
              </span>
              <p class="position-card__body-item-description">
                {{ $t(`${langPath}.candidatesInProcess`) }}
              </p>
            </li>
          </ul>
        </div>
      </div>

      <footer class="position-card__footer">
        <div class="position-card__footer-container">
          <div class="position-card__footer-actions">
            <ev-button
              color="red"
              variant="secondary"
              size="small"
              data-test="see-details-button"
              @click="redirectToWorkflowBoard"
            >
              {{ $t(`${langPath}.callsToAction.seeDetails`) }}
            </ev-button>
          </div>
        </div>
      </footer>
    </div>
    <div class="position-card__menu">
      <app-dropdown-menu data-test="menu">
        <template slot="title">
          <ev-icon name="ellipsis-v" color="gray60" />
        </template>

        <app-item-menu
            v-if="editable"
            data-test="edit-position-link"
            @click="redirectToEdit"
            >
            {{ $t(`${langPath}.callsToAction.edit`) }}
        </app-item-menu>

          <app-item-menu
              v-if="archivable"
              data-test="archive-position-link"
              @click="openArchivePositionModal"
              >
              {{ $t(`${langPath}.callsToAction.archive`) }}
          </app-item-menu>

            <app-item-menu
                v-if="unarchivable"
                data-test="unarchive-position-link"
                @click="handleUnarchivePosition"
                >
                {{ $t(`${langPath}.callsToAction.unarchive`) }}
            </app-item-menu>

              <app-item-menu
                  v-if="publishable"
                  data-test="publish-position-link"
                  @click="redirectToJobPosting"
                  >
                  {{ $t(`${langPath}.callsToAction.publish`) }}
              </app-item-menu>

                <app-item-menu
                    v-if="isPublished"
                    data-test="copy-link"
                    @click="copyLink"
                    >
                    {{ $t(`${langPath}.callsToAction.copyLink`) }}
                </app-item-menu>
      </app-dropdown-menu>

      <archive-position-modal
          v-if="showArchivePositionModal"
          data-test="archive-position-modal"
          :position="position"
          @confirm="handleArchivePosition"
          @cancel="closeArchivePositionModal"
          />
    </div>
  </article>
</template>

<script>
import EvBadge from '@revelotech/everestV2/EvBadge'
import EvButton from '@revelotech/everestV2/EvButton'
import EvIcon from '@revelotech/everestV2/EvIcon'
import { TheNotificationCardList } from '@revelotech/everest'

import { copyToClipboard } from '@/helpers/clipboard'
import ArchivePositionModal from './ArchivePositionModal'

export default {
  name: 'PositionCard',
  components: {
    EvBadge,
    EvButton,
    EvIcon,
    ArchivePositionModal
  },
  props: {
    position: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath,
      showArchivePositionModal: false
    }
  },
  computed: {
    isDraft () {
      return this.position.draft || false
    },
    isRemote () {
      return this.position.remote || false
    },
    isPublished () {
      return this.position.published || false
    },
    isPrivate () {
      return this.position.privatePosition || false
    },
    isVisible () {
      return this.position.visible || false
    },
    archivable () {
      return !this.unarchivable
    },
    unarchivable () {
      return !this.isVisible
    },
    editable () {
      return this.isVisible
    },
    contractTypes () {
      return this.position.contractTypes || []
    },
    publishable () {
      if (!this.isVisible) return false

      return !this.isPublished && !this.isDraft
    },
    hasLocationBadge () {
      if (this.isRemote) return false

      return !!this.position.city || !!this.position.state
    },
    positionCardClass () {
      return {
        'position-card--draft': this.isDraft
      }
    },
    locationBadgeText () {
      return `${this.position.city || ''} ${this.position.state || ''}`.trim()
    }
  },
  methods: {
    redirectToEdit () {
      this.$router.push({
        name: 'PositionsEdit',
        params: { positionId: this.position.externalId },
        query: { redirect: window.location.href }
      })
    },
    redirectToWorkflowBoard () {
      if (!this.isVisible) return

      this.$router.push({
        name: 'WorkflowBoard',
        params: { positionId: this.position.id }
      })
    },
    copyLink () {
      if (copyToClipboard(this.position.sitePositionLink)) {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.copySuccess`),
          modifier: 'success'
        })
      } else {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.copyFailure`),
          modifier: 'error'
        })
      }
    },
    openArchivePositionModal () {
      this.showArchivePositionModal = true
    },
    closeArchivePositionModal () {
      this.showArchivePositionModal = false
    },
    handleArchivePosition () {
      this.closeArchivePositionModal()
      this.$emit('archivePosition', this.position.id)
    },
    handleUnarchivePosition () {
      this.$emit('unarchivePosition', this.position.id)
    },
    redirectToJobPosting () {
      this.$router.push({
        name: 'JobPosting', params: { positionId: this.position.id }
      })
    }
  }
}
</script>

<style lang="scss">
.position-card {
  @include margin(bottom, 4);

  --card-width: 100%;
  --card-min-height: 260px;

  background-color: $white;
  display: flex;
  min-height: var(--card-min-height);
  padding: $base * 4;
  width: var(--card-width);

  @include breakpoint(md) {
    --card-width: 394px;
  }

  &__container {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    justify-content: space-between;
    padding-top: 2*$base;
    width: 100%;
  }

  &__header {
    align-items: flex-start;
    display: flex;

    &-container {
      align-items: center;
      display: flex;
      flex: 1;
    }

    &-title {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      flex-grow: 2;
      -webkit-line-clamp: 2;
      margin-right: $base;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-private-icon {
      margin-right: $base;
      margin-top: -$base * 2;
    }
  }

  &__details {
    display: flex;
    flex-direction: row;
    margin-top: $base * 2;

    &-badge {
      @include margin(right, 2);
    }
  }

  &__draft {
    &-badge {
      margin-right: $base * 6;
    }
  }

  &__body {
    margin-top: $base * 6;

    &-items {
      display: flex;
      gap: $base * 9;
    }

    &-item {
      align-items: baseline;
      display: flex;
      flex: 1;

      &-count {
        font: $tx-title-2;
        margin-right: $base * 5;
      }

      &-description {
        font: $tx-body;
      }
    }
  }

  &__footer {
    margin-top: $base * 8;
  }

  &__title {
    @extend %h6;

    align-items: center;
    cursor: pointer;
    flex: 2;
  }

  &__menu {
    ul {
      z-index: 1;
    }
  }
}
</style>
